<template>
  <div>
    <!-- DESKTOP -->
    <div class="mb-xl-action" v-if="!isMobile">
      <v-slide-group
        multiple
        show-arrows
        prev-icon="mdi-chevron-left-circle-outline"
        next-icon="mdi-chevron-right-circle-outline"
      >
        <v-slide-item
          v-for="data in listActionToday"
          :key="data.id"
          v-slot="{ active, toggle }"
        >
          <v-card
            class="mx-2 hover-action mt-0 mb-5 d-flex justify-center align-center"
            :input-value="active"
            active-class="purple white--text"
            style="border-radius: 8px"
            depressed
            rounded
            width="310"
            elevation="0"
          >
            <v-row align="center">
              <v-col class="mt-2 pl-6 pr-3" cols="3">
                <v-img width="60" :src="data.icon"></v-img>
              </v-col>
              <v-col class="pt-7 pb-4 pl-2 pr-0" cols="5">
                <p class="text-black text-left">{{ data.name }}</p>
              </v-col>
              <v-col class="pa-0 d-flex" cols="4">
                <v-hover v-slot="{ hover }">
                  <!-- small -->
                  <v-btn
                    @click="irApp(data), toggle"
                    rounded
                    small
                    style="color: #fff"
                    :style="{
                      'background-color': hover ? '#324CA1' : '#466BE3',
                    }"
                    class="pr-0"
                  >
                    ver más
                    <img
                      width="20"
                      src="../../assets/icon/arrow-right-button.svg"
                    />
                  </v-btn>
                </v-hover>
              </v-col>
            </v-row>
          </v-card>
        </v-slide-item>
      </v-slide-group>
    </div>
    <!-- MOBILE -->
    <div class="mb-sm-action" v-else>
      <v-slide-group
        multiple
        show-arrows
        prev-icon="mdi-chevron-left-circle-outline"
        next-icon="mdi-chevron-right-circle-outline"
      >
        <v-slide-item
          v-for="data in listActionToday"
          :key="data.id"
          v-slot="{ active, toggle }"
        >
          <v-card
            class="mx-2 hover-action mt-0 mb-5"
            :input-value="active"
            active-class="purple white--text"
            style="border-radius: 8px; display: flex"
            depressed
            rounded
            @click="irApp(data), toggle"
            width="230"
          >
            <v-row align="center">
              <v-col cols="3" class="mb-0">
                <v-img width="60" class="ml-3" :src="data.icon"></v-img>
              </v-col>
              <v-col cols="9">
                <p class="text-blue text-left pb-0 mb-0">{{ data.name }}</p>
              </v-col>
            </v-row>
          </v-card>
        </v-slide-item>
      </v-slide-group>
    </div>
  </div>
</template>
<script>
import alert from "@/mixins/alert";

import { Hooper, Slide, Pagination as HooperPagination } from "hooper";
import "hooper/dist/hooper.css";

export default {
  mixins: [alert],
  name: "Views",
  components: {
    Hooper,
    Slide,
    HooperPagination,
  },
  data() {
    return {
      itemsToShow: 1.3,
      origin: window.location.origin,
      listActionToday: [

        {
          id: 1,
          icon: origin + "/icon/solicitudes-1v2.svg",
          name: "Reportar incapacidad",
          url: "https://colaborador.brm.com.co/WEBKACTUS/",
          externarUrl: true,
        },
        {
          id: 2,
          icon: origin + "/icon/solicitudes-4v2.svg",
          name: "Solicitar permiso",
          url: "https://colaborador.brm.com.co/WEBKACTUS/",
          externarUrl: true,
        },
        {
          id: 3,
          icon: origin + "/icon/solicitudes-2v2.svg",
          name: "Solicitar vacaciones",
          url: "https://colaborador.brm.com.co/WEBKACTUS/",
          externarUrl: true,
        },
        {
          id: 4,
          icon: origin + "/icon/solicitudes-3v2.svg",
          name: "Solicitar certificados",
          url: "https://colaborador.brm.com.co/WEBKACTUS/",
          externarUrl: true,
        },
        {
          id: 5,
          icon: origin + "/icon/user-profile.svg",
          name: "Ver perfil",
          url: "profile",
          externarUrl: false,
        },
        {
          id: 6,
          icon: origin + "/icon/forms-square.svg",
          name: "Ver encuestas",
          url: "forms",
          externarUrl: false,
        },
      ],
      isMobile: false
    };
  },
  methods: {
    irApp(item) {
      if (item.externarUrl) window.open(item.url, "_black");
      else this.$router.push(item.url);
    },
    checkIfMobile() {
      this.isMobile = window.innerWidth <= 1023;
    },
  },
  created() {
    if (screen.width >= 768) {
      this.itemsToShow = 3.1;
    } else if (screen.width >= 425) {
      this.itemsToShow = 1.7;
    } else if (screen.width >= 375) {
      this.itemsToShow = 1.5;
    }
    this.checkIfMobile();
    window.addEventListener('resize', this.checkIfMobile);
  },
  mounted() {},
  beforeDestroy() {
    window.removeEventListener('resize', this.checkIfMobile);
  },
};
</script>
<style lang="less">
@import "../../assets/css/main";

.mdi-chevron-right-circle-outline::before {
  // content: "\F0B2A";
  color: #466be3;
}

.mdi-chevron-left-circle-outline::before {
  // content: "\F0B28";
  color: #466be3;
}

.v-icon--disabled::before {
  color: #c6c6c6 !important;
}

.v-pagination__item {
  height: 15px !important;
  min-width: 15px !important;
  font-size: 1px !important;
}
.v-pagination__navigation {
  box-shadow: none !important;
  height: 18px !important;
  width: 18px !important;
}

.theme--light.v-pagination .v-pagination__navigation {
  background-color: #ffffff00;
}
.v-pagination__navigation .v-icon {
  color: #324ba1 !important;
}

.mb-sm-action {
  display: none;
}

.hover-action:hover {
  box-shadow: 0px 2px 4px rgba(62, 123, 250, 0.4),
    0px 8px 16px rgba(62, 123, 250, 0.16) !important;
  border-radius: 10px !important;
}

.v-slide-group__next,
.v-slide-group__prev {
  justify-content: center !important;
  min-width: 28px !important;
  flex: 0 1 24px !important;
}

/* LAPTOP 4k */

@media (max-width: 1900px) {
}

/* LAPTOP LARGE */

@media (max-width: 1600px) {
}

/* LAPTOP LARGE */

@media (max-width: 1400px) {
}

/* LAPTOP NORMAL */

@media (max-width: 1200px) {
}

/* TABLET */

@media (max-width: 1023px) {
  .mb-xl-action {
    display: none;
  }
  .mb-sm-action {
    display: block;
    text-align: initial;
  }
  .today-action-sm {
    height: 80px;
    margin-top: 15px;
    box-shadow: 0px 2px 4px rgba(62, 123, 250, 0.4),
      0px 8px 16px rgba(62, 123, 250, 0.16) !important;
    border-radius: 10px !important;
  }

  .text-blue-sm {
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    color: #466be3;
    font-family: "AsapRegular";
  }
  .v-slide-group__next,
  .v-slide-group__prev {
    justify-content: center !important;
    min-width: 33px !important;
    flex: 0 1 24px !important;
  }
}

/* MOBILE L*/
@media (max-width: 767px) {
  .mb-xl-action {
    display: none;
  }
  .mb-sm-action {
    display: block;
    text-align: initial;
  }

  .today-action-sm {
    height: 80px;
    margin-top: 15px;
    box-shadow: 0px 2px 4px rgba(62, 123, 250, 0.4),
      0px 8px 16px rgba(62, 123, 250, 0.16) !important;
    border-radius: 10px !important;
  }

  .text-blue-sm {
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    color: #466be3;
    font-family: "AsapRegular";
  }
}

/* MOBILE M */
@media (max-width: 375px) {
  .mb-xl-action {
    display: none;
  }
  .mb-sm-action {
    display: block;
    text-align: initial;
  }

  .today-action-sm {
    height: 80px;
    margin-top: 15px;
    box-shadow: 0px 2px 4px rgba(62, 123, 250, 0.4),
      0px 8px 16px rgba(62, 123, 250, 0.16) !important;
    border-radius: 10px !important;
  }

  .text-blue-sm {
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    color: #466be3;
    font-family: "AsapRegular";
  }
  .v-slide-group__next,
  .v-slide-group__prev {
    justify-content: center !important;
    min-width: 33px !important;
    flex: 0 1 24px !important;
  }
}

/* MOBILE S */
@media (max-width: 320px) {
  .mb-xl-action {
    display: none;
  }
  .mb-sm-action {
    display: block;
    text-align: initial;
  }

  .today-action-sm {
    height: 80px;
    margin-top: 15px;
    box-shadow: 0px 2px 4px rgba(62, 123, 250, 0.4),
      0px 8px 16px rgba(62, 123, 250, 0.16) !important;
    border-radius: 10px !important;
  }

  .text-blue-sm {
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    color: #466be3;
    font-family: "AsapRegular";
  }
  .v-slide-group__next,
  .v-slide-group__prev {
    justify-content: center !important;
    min-width: 33px !important;
    flex: 0 1 24px !important;
  }
}
</style>
