var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-slide-group',{attrs:{"multiple":"","show-arrows":"","prev-icon":"mdi-chevron-left-circle-outline","next-icon":"mdi-chevron-right-circle-outline"}},_vm._l((_vm.listView),function(data){return _c('v-slide-item',{key:data.id,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-card',{staticClass:"mx-2 hover-action mt-0 mb-5",staticStyle:{"border-radius":"8px !important","box-shadow":"none"},attrs:{"input-value":active,"active-class":"purple white--text","depressed":"","rounded":"","width":"420"}},[_c('div',{staticClass:"d-flex flex-no-wrap justify-space-between"},[_c('v-avatar',{attrs:{"size":"170","tile":""}},[_c('v-img',{staticClass:"rounded-lg news-line__img",staticStyle:{"border-top-right-radius":"0 !important","border-bottom-right-radius":"0 !important"},attrs:{"src":data.img_small}})],1),_c('div',{staticStyle:{"text-align":"initial"}},[_c('v-card-title',{staticClass:"text-small pb-2 pt-5"},[_vm._v(" "+_vm._s(data.post_date)+" "),_c('v-spacer'),_c('v-chip',{attrs:{"text-color":"#fff","color":"#466BE3","small":""}},[_vm._v(" "+_vm._s(data.tagName)+" ")])],1),_c('v-card-title',{staticClass:"text-title text-title-1 pt-1 pb-0",domProps:{"textContent":_vm._s(data.post_title)}}),_c('v-card-text',{staticClass:"text-basic",staticStyle:{"height":"35px"}},[_c('div',{staticClass:"text-1-lines",staticStyle:{"color":"#626262"},domProps:{"innerHTML":_vm._s(data.post_excerpt)}})]),_c('v-card-actions',{staticClass:"pt-0"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',{staticStyle:{"color":"#fff"},style:({
                    'background-color': hover ? '#324CA1' : '#466BE3',
                  }),attrs:{"to":{
                    name: 'newsDetails',
                    params: { id: data.post_id },
                  },"block":"","rounded":"","small":""},on:{"click":toggle}},[_vm._v(" VER MÁS ")])]}}],null,true)})],1)],1)],1)])]}}],null,true)})}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }