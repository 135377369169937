<template>
  <div>
    <v-slide-group
      multiple
      show-arrows
      prev-icon="mdi-chevron-left-circle-outline"
      next-icon="mdi-chevron-right-circle-outline"
    >
      <v-slide-item
        v-for="data in listView"
        :key="data.id"
        v-slot="{ active, toggle }"
      >
        <v-card
          class="mx-2 hover-action mt-0 mb-5"
          :input-value="active"
          active-class="purple white--text"
          style="border-radius: 8px !important; box-shadow: none"
          depressed
          rounded
          width="420"
        >
          <div class="d-flex flex-no-wrap justify-space-between">
            <v-avatar size="170" tile>
              <v-img
                class="rounded-lg news-line__img"
                :src="data.img_small"
                style="
                  border-top-right-radius: 0 !important;
                  border-bottom-right-radius: 0 !important;
                "
              ></v-img>
            </v-avatar>

            <div style="text-align: initial">
              <v-card-title class="text-small pb-2 pt-5">
                {{ data.post_date }}
                <v-spacer></v-spacer>
                <v-chip text-color="#fff" color="#466BE3" small>
                  {{ data.tagName }}
                </v-chip>
              </v-card-title>

              <v-card-title
                class="text-title text-title-1 pt-1 pb-0"
                v-text="data.post_title"
              ></v-card-title>
              <v-card-text style="height: 35px" class="text-basic">
                <div
                  class="text-1-lines"
                  style="color: #626262"
                  v-html="data.post_excerpt"
                ></div>
              </v-card-text>

              <v-card-actions class="pt-0">
                <v-hover v-slot="{ hover }">
                  <v-btn
                    :to="{
                      name: 'newsDetails',
                      params: { id: data.post_id },
                    }"
                    block
                    rounded
                    small
                    style="color: #fff"
                    :style="{
                      'background-color': hover ? '#324CA1' : '#466BE3',
                    }"
                    @click="toggle"
                  >
                    VER MÁS
                  </v-btn>
                </v-hover>
              </v-card-actions>
            </div>
          </div>
        </v-card>
      </v-slide-item>
    </v-slide-group>

    <!-- <hooper
      ref="carousel"
      :infiniteScroll="true"
      :vertical="false"
      style="height: 190px"
      :itemsToShow="2.8"
      :centerMode="true"
      :playSpeed="4000"
    >
      <template v-for="data in listView">
        <slide :key="data.post_id">
          <v-card width="420px">
            <div class="d-flex flex-no-wrap justify-space-between">
              <v-avatar size="170" tile>
                <v-img class="rounded-lg" :src="data.img_small"></v-img>
              </v-avatar>

              <div style="text-align: initial">
                <v-card-title class="text-small pb-2 pt-5">
                  {{ data.post_date }}
                  <v-spacer></v-spacer>
                  <v-chip color="#D4DBFF" small> {{ data.tagName }} </v-chip>
                </v-card-title>

                <v-card-title
                  class="text-title text-title-1 pt-1 pb-0"
                  v-text="data.post_title"
                ></v-card-title>
                <v-card-text style="height: 35px" class="text-basic">
                  <div class="text-1-lines" v-html="data.post_excerpt"></div>
                </v-card-text>

                <v-card-actions class="pt-0">
                  <v-btn
                    :to="{
                      name: 'newsDetails',
                      params: { id: data.post_id },
                    }"
                    class="btn-main"
                    block
                    dark
                    rounded
                    small
                    >VER MÁS</v-btn
                  >
                </v-card-actions>
              </div>
            </div>
          </v-card>
        </slide>
      </template>
    </hooper> -->
  </div>
</template>
<script>
import alert from "@/mixins/alert";
import Api from "@/utils/api";
import moment from "moment";
import { Hooper, Slide, Pagination as HooperPagination } from "hooper";
import "hooper/dist/hooper.css";

export default {
  mixins: [alert],
  name: "Views",
  components: {
    Hooper,
    Slide,
    HooperPagination,
  },
  data() {
    return {
      page: 1,
      loading: {
        btn: false,
      },
      userInfo:{},
      token: "",
      infoToken: "",
      show: false,
      listView: [],
      currentUser:[],
    };
  },
  methods: {
    getToken() {
      var token = localStorage.getItem("token");
      var tokenb64 = localStorage.getItem("tokenB64");
      this.token = tokenb64;
      var div = token.split(".");
      if (div.length >= 2) {
        this.infoToken = JSON.parse(atob(div[1]));
        //this.infoToken = JSON.parse(atob(div[1]));
        var nameList = this.infoToken.fullName.split(" ");
        var docList = this.infoToken.document;
        //console.log(this.infoToken);

        if (nameList.length > 1) {
          if (nameList[0] != "") {
            this.name = nameList[0];
          } else {
            this.name = nameList[1];
          }
        } else {
          this.name = "";
        }
        // console.log(JSON.parse(atob(div[1])));
      } else {
        this.infoToken = {
          document: "",
        };
      }
    },
    getView() {
      this.loadingAll = true;
      let cc; 
      let loginId; 
      cc = this.currentUser.document_number;
      loginId = this.currentUser.profile.id;
        Api.noAuth()
        .getView2(cc,loginId)
        .then((resp) => resp.json())
        .then((data) => {          
          if (data.cod == 0) {
            this.listView = data.data;
            for (let i = 0; i < this.listView.length; i++) {
              let date = moment(this.listView[i].post_date).format("L");
              this.listView[i].post_date = date;
              if (this.listView[i].tags.length > 0) {
                this.listView[i].tagName = this.listView[i].tags[0];
              } else {
                this.listView[i].tagName = "Otro";
              }
            }
          }          
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  created() {
    this.getToken();
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
  },
  mounted() {
    this.currentUser = this.$store.getters.getUser;
    if (this.currentUser) {
      this.getView();
    }
  },
  watch: {
    user(newUser) {
      if (newUser) {
        this.currentUser = newUser;
        this.getView();
      }
    },
  },
};
</script>
<style lang="less">
@import "../../assets/css/main";

.mdi-chevron-right-circle-outline::before {
  // content: "\F0B2A";
  color: #466be3;
}

.mdi-chevron-left-circle-outline::before {
  // content: "\F0B28";
  color: #466be3;
}

.v-icon--disabled::before {
  color: #c6c6c6 !important;
}

.news-line__img {
  border-top-right-radius: 0 !important;
}

.v-pagination__item {
  height: 15px !important;
  min-width: 15px !important;
  font-size: 1px !important;
}
.v-pagination__navigation {
  box-shadow: none !important;
  height: 18px !important;
  width: 18px !important;
}

.theme--light.v-pagination .v-pagination__navigation {
  background-color: #ffffff00;
}
.v-pagination__navigation .v-icon {
  color: #324ba1 !important;
}

.v-slide-group__next,
.v-slide-group__prev {
  justify-content: center !important;
  min-width: 28px !important;
  flex: 0 1 24px !important;
}
</style>
