<template>
  <div>
    <!-- DESKTOP -->
    <div class="mb-xl-flash" v-if="!isMobile">
      <v-card color="#f6f9fe" elevation="0" height="490">
        <v-virtual-scroll item-height="250" :items="listVideo">
          <template v-slot:default="{ item }">
            <v-list-item :key="item.post_id" class="pr-0 pl-0">
              <v-col cols="12" class="pt-0 pl-0 pr-0">
                <div class="demo-wrap" :ref="'printMe-' + item.post_id">
                  <v-img
                    loading="lazy"
                    @click="openM(item)"
                    gradient="0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)"
                    v-if="!item.show"
                    style="cursor: pointer; border-radius: 10px"
                    :key="item.post_id"
                    :id="'img-v' + item.post_id"
                    :src="item.outstanding_image"
                    class="rounded-video-img demo-bg"
                    height="240"
                  >
                    <img
                      src="../../assets/icon/play-circle.svg"
                      color="#fff"
                      class="mt-12 flash__img-play"
                      style="font-size: 100px"
                      @click="openM(item)"
                    />
                    <v-layout justify-center>
                      <span
                        class="text-1-1-lines text-title px-5"
                        style="color: #fff !important"
                      >
                        {{ item.post_title }}</span
                      >
                    </v-layout>
                  </v-img>
                </div>
              </v-col>
            </v-list-item>
          </template>
        </v-virtual-scroll>
      </v-card>
    </div>

    <!-- MOBILE -->
    <div class="mb-sm-flash" v-else>
      <hooper
        class=""
        ref="carousel"
        :infiniteScroll="true"
        :vertical="false"
        style="height: 100px"
        :itemsToShow="itemsToShow"
        :centerMode="true"
        :playSpeed="4000"
      >
        <slide v-for="data in listVideo" :key="data.id">
          <v-img
            loading="lazy"
            style="cursor: pointer"
            :key="data.post_id"
            :id="'img-v' + data.post_id"
            @click="openM(data)"
            :src="data.outstanding_image"
            class="rounded-video"
          >
            <v-icon
              style="font-size: 50px"
              class="ml-1 mt-6"
              color="#fff"
              x-large
            >
              mdi-play
            </v-icon>
          </v-img>
        </slide>
      </hooper>
    </div>

    <v-overlay opacity="0.8" color="#000" :value="modal.video" persistent>
      <br />
      <v-layout justify-center>
        <v-btn class="mb-2" dark text small @click="modal.video = false">
          <strong> cerrar </strong>
        </v-btn>
      </v-layout>

      <video
        autoplay
        style="background-color: #000"
        :ref="'video' + infoVideo.post_id"
        :id="'video' + infoVideo.post_id"
        :src="infoVideo.video"
      />
    </v-overlay>

    <v-overlay :value="loadingAll">
      <v-progress-circular :size="120" color="#466BE3" indeterminate>
        Cargando...
      </v-progress-circular>
    </v-overlay>

    <v-dialog max-width="800" v-model="open">
      <v-card elevation="0" style="background-color: #f0f8ff00">
        <v-layout justify-center>
          <v-btn color="#466be3" class="mb-2" dark small @click="open = false">
            <strong> cerrar </strong>
          </v-btn>
        </v-layout>
        <video-player
          :height="220"
          class="video-player-box"
          ref="videoPlayer1"
          :options="playerOptions"
          :playsinline="true"
          customEventName="customstatechangedeventname"
          @play="onPlayerPlay($event)"
          @pause="onPlayerPause($event)"
          @ended="onPlayerEnded($event)"
          @playing="onPlayerPlaying($event)"
          @statechanged="playerStateChanged($event)"
          @ready="playerReadied"
        >
        </video-player>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { Hooper, Slide, Pagination as HooperPagination } from "hooper";
import "hooper/dist/hooper.css";

import { Flicking } from "@egjs/vue-flicking";
import alert from "@/mixins/alert";
import Api from "@/utils/api";
import Hls from "hls.js";

import $ from "jquery";

export default {
  mixins: [alert],
  components: {
    Flicking: Flicking,
    Hls,
    Hooper,
    Slide,
    HooperPagination,
    isMobile: false
  },
  data() {
    return {
      playerOptions: {
        // videojs options
        muted: false,
        autoplay: true,
        language: "en",
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [
          {
            type: "application/x-mpegURL",
            src: "http://d2zihajmogu5jn.cloudfront.net/bipbop-advanced/bipbop_16x9_variant.m3u8",
          },
        ],
        poster:
          "https://vhls.nyc3.digitaloceanspaces.com/okanwp/wp-content/uploads/2022/12/06154201/Recomiende-Okan-1-1.jpg",
      },
      open: false,
      showA: false,
      itemsToShow: 4.3,
      value: 0,
      query: false,
      show: true,
      interval: 0,
      listVideo: [],
      output: "",
      loadingAll: false,
      userInfo:{},
      token: "",
      infoToken: "",
      modal: {
        video: false,
      },
      infoVideo: {
        post_id: null,
        video: null,
      },
      nextVideo: null,
      currentUser:[],
    };
  },
  watch: {
    open: function (e) {
      if (!e) {
        this.playerOptions.muted = true;
      }
    },
    user(newUser) {
      if (newUser) {
        this.currentUser = newUser;
        this.getVideos();
      }
    },
  },
  methods: {
    checkIfMobile() {
      this.isMobile = window.innerWidth <= 1023;
    },
    getToken() {
      var token = localStorage.getItem("token");
      var tokenb64 = localStorage.getItem("tokenB64");
      this.token = tokenb64;
      var div = token.split(".");
      if (div.length >= 2) {
        this.infoToken = JSON.parse(atob(div[1]));
        //this.infoToken = JSON.parse(atob(div[1]));
        var nameList = this.infoToken.fullName.split(" ");
        var docList = this.infoToken.document;
        //console.log(this.infoToken);

        if (nameList.length > 1) {
          if (nameList[0] != "") {
            this.name = nameList[0];
          } else {
            this.name = nameList[1];
          }
        } else {
          this.name = "";
        }
        // console.log(JSON.parse(atob(div[1])));
      } else {
        this.infoToken = {
          document: "",
        };
      }
    },
    // FLASH NUEVO
    openM(item) {
      this.open = true;
      this.playerOptions.muted = false;
      this.playerOptions.sources[0].src = item.video;
    },
    // listen event
    onPlayerPlay(player) {
      // console.log('player play!', player)
    },
    onPlayerPause(player) {
      // console.log('player pause!', player)
    },
    // or listen state event
    playerStateChanged(playerCurrentState) {
      // console.log('player current update state', playerCurrentState)
    },
    // player is ready
    playerReadied(player) {
      console.log("the player is readied", player);
      // you can use it to do something...
      // player.[methods]
    },
    // FLASH ANTES
    reviewVideo(data) {
      console.log("Actual: " + data.post_id);
      console.log("Anterior: " + this.nextVideo);

      var key = "videoAuto" + this.nextVideo; // anterior
      var keyIcon = "icon-video" + this.nextVideo; // anterior
      var keyIconNew = "icon-video" + data.post_id;
      if (this.nextVideo != null) {
        if (this.nextVideo != data.post_id) {
          console.log("Click elemento");
          console.log(this.$refs[key]);
          this.$refs[key].pause();
        }
      }
      this.nextVideo = data.post_id;
    },
    getFullscreen(element) {
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      }
    },
    queryAndIndeterminate() {
      // this.query = true
      this.show = true;
      this.value = 0;
      this.interval = setInterval(() => {
        if (this.value === 100) {
          clearInterval(this.interval);
          this.show = false;
          return setTimeout(this.queryAndIndeterminate, 2000);
        }
        this.value += 25;
      }, 1000);
    },
    openVideo(data) {
      // console.log("*****");
      // console.log(data);
      // console.log(i);

      // this.showA = true;
      // console.log(this.listVideo);
      // setTimeout(() => {
      //   this.listVideo[i].show = true;
      // }, 100);
      // this.$forceUpdate();
      this.modal.video = true;
      this.infoVideo = data;
      // console.log(data);
      var videoId = document.getElementById("video" + data.post_id);
      setTimeout(() => {
        var tiempo = $("#video5257")[0].duration;
        this.queryAndIndeterminate();
        // console.log(tiempo);
      }, 1000);
    },
    getVideos() {
      this.loadingAll = true;
      let cc; 
      let loginId; 
      cc = this.currentUser.document_number;
      loginId = this.currentUser.profile.id;
      // Llamada al método getInfoUser() para obtener la información del usuario
        Api.noAuth()
        .showVideos(cc,loginId)
        .then((resp) => resp.json())
        .then((data) => {
          if (data.cod == 0) {
            this.listVideo = data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loadingAll = false;
          this.listVideo.forEach((element, i) => {
            let video = document.getElementById(`videoAuto${element.post_id}`);
            let formatIndex = element.video.lastIndexOf(".") + 1;
            let format = element.video.slice(formatIndex);
            // let vimeo = videoSrc.includes("vimeo");

            element.newImg = "";
            this.print(element.post_id, i);

            element.show = false;
            if (format === "m3u8") {
              let videoSrc = element.video;
              if (Hls.isSupported()) {
                let hls = new Hls();
                hls.loadSource(videoSrc);
                hls.attachMedia(video);
              } else if (video.canPlayType("application/vnd.apple.mpegurl")) {
                video.src = videoSrc;
              }
            }
          });
        });
    },
    async print(id, pos) {
      const el = this.$refs["printMe-" + id];
      // add option type to get the image version
      // if not provided the promise will return
      // the canvas.
      const options = {
        type: "dataURL",
      };
      // console.log("AQUIOooo",this.listVideo[i],i)
      // console.log("AQUIOooo22222",await this.$html2canvas(el, options))
      this.listVideo[i].newImg = await this.$html2canvas(el, options);
      // console.log("AQUIOooo3333",this.listVideo[pos])
    },
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
  },
  created() {
    this.getToken();
    if (screen.width > 425) {
      this.itemsToShow = 8.3;
    }
    this.checkIfMobile();
    window.addEventListener('resize', this.checkIfMobile);
  },
  mounted() {
    this.currentUser = this.$store.getters.getUser;
    if (this.currentUser) {
      this.getVideos();
    }
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.checkIfMobile);
  },
};
</script>
<style>
.triangulo {
  width: 0;
  height: 0;
  border-left: 100px solid #f0ad4e;
  border-top: 50px solid transparent;
  border-bottom: 50px solid transparent;
}
.flash__demo-content {
  width: 100% !important;
  z-index: 2;
  position: relative;
  padding-left: 12px;
  padding-right: 12px;
}
/* .demo-wrap {
  position: relative; 
} */

.demo-bg {
  /* opacity: 0.6; */
  /* position: absolute; */
  left: 0;
  top: 0;
  width: 100%;
  height: auto;
}

/* .demo-content {
  position: relative;
} */

.video-js {
  width: auto !important;
  height: 550px;
}

.weather-panel {
  width: 200px;
  height: 250px;
  background-color: violet;
  border: 1px solid;
}

.video {
  left: 0 !important;
  top: 0 !important;
  height: 100% !important;
  width: 100% !important;
  position: absolute !important;
}

.player {
  position: initial !important;
}

.video-js {
  background-color: black !important;
}

.mb-sm-flash {
  display: none;
}

.flash__img-play {
  cursor: pointer;
}

/* LAPTOP 4k */

@media (max-width: 1900px) {
}

/* LAPTOP LARGE */

@media (max-width: 1600px) {
}

/* LAPTOP LARGE */

@media (max-width: 1400px) {
}

/* LAPTOP NORMAL */

@media (max-width: 1200px) {
}

/* TABLET */

@media (max-width: 1023px) {
  .mb-xl-flash {
    display: none;
  }
  .mb-sm-flash {
    display: block;
    text-align: initial;
  }
  .rounded-video {
    border-radius: 10px;
    width: 60px;
    height: 96px;
    filter: drop-shadow(0px 0px 1px rgba(62, 123, 250, 0.2))
      drop-shadow(0px 2px 4px rgba(70, 107, 227, 0.4));
  }
  .video-js {
    width: auto !important;
    height: 430px;
  }
}

/* MOBILE L*/
@media (max-width: 767px) {
  .mb-xl-flash {
    display: none;
  }
  .mb-sm-flash {
    display: block;
    text-align: initial;
  }
  .rounded-video {
    width: 60px;
    height: 96px;
    filter: drop-shadow(0px 0px 1px rgba(62, 123, 250, 0.2))
      drop-shadow(0px 2px 4px rgba(70, 107, 227, 0.4));
  }
  .video-js {
    width: auto !important;
    height: 350px;
  }
}

/* MOBILE M */
@media (max-width: 375px) {
  .mb-xl-flash {
    display: none;
  }
  .mb-sm-flash {
    display: block;
    text-align: initial;
  }
  .rounded-video {
    width: 60px;
    height: 96px;
    filter: drop-shadow(0px 0px 1px rgba(62, 123, 250, 0.2))
      drop-shadow(0px 2px 4px rgba(70, 107, 227, 0.4));
  }
  .video-js {
    width: auto !important;
    height: 300px;
  }
}

/* MOBILE S */
@media (max-width: 320px) {
  .mb-xl-flash {
    display: none;
  }
  .mb-sm-flash {
    display: block;
    text-align: initial;
  }
  .rounded-video {
    width: 60px;
    height: 96px;
    filter: drop-shadow(0px 0px 1px rgba(62, 123, 250, 0.2))
      drop-shadow(0px 2px 4px rgba(70, 107, 227, 0.4));
    border: 1px solid #4c6bf8;
  }
  .v-dialog {
    margin: 0px !important;
  }

  .video-js {
    width: auto !important;
    height: 300px;
  }
}
</style>
