var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.isMobile)?_c('div',{staticClass:"mb-xl-action"},[_c('v-slide-group',{attrs:{"multiple":"","show-arrows":"","prev-icon":"mdi-chevron-left-circle-outline","next-icon":"mdi-chevron-right-circle-outline"}},_vm._l((_vm.listActionToday),function(data){return _c('v-slide-item',{key:data.id,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-card',{staticClass:"mx-2 hover-action mt-0 mb-5 d-flex justify-center align-center",staticStyle:{"border-radius":"8px"},attrs:{"input-value":active,"active-class":"purple white--text","depressed":"","rounded":"","width":"310","elevation":"0"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"mt-2 pl-6 pr-3",attrs:{"cols":"3"}},[_c('v-img',{attrs:{"width":"60","src":data.icon}})],1),_c('v-col',{staticClass:"pt-7 pb-4 pl-2 pr-0",attrs:{"cols":"5"}},[_c('p',{staticClass:"text-black text-left"},[_vm._v(_vm._s(data.name))])]),_c('v-col',{staticClass:"pa-0 d-flex",attrs:{"cols":"4"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',{staticClass:"pr-0",staticStyle:{"color":"#fff"},style:({
                    'background-color': hover ? '#324CA1' : '#466BE3',
                  }),attrs:{"rounded":"","small":""},on:{"click":function($event){_vm.irApp(data), toggle}}},[_vm._v(" ver más "),_c('img',{attrs:{"width":"20","src":require("../../assets/icon/arrow-right-button.svg")}})])]}}],null,true)})],1)],1)],1)]}}],null,true)})}),1)],1):_c('div',{staticClass:"mb-sm-action"},[_c('v-slide-group',{attrs:{"multiple":"","show-arrows":"","prev-icon":"mdi-chevron-left-circle-outline","next-icon":"mdi-chevron-right-circle-outline"}},_vm._l((_vm.listActionToday),function(data){return _c('v-slide-item',{key:data.id,scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var active = ref.active;
                  var toggle = ref.toggle;
return [_c('v-card',{staticClass:"mx-2 hover-action mt-0 mb-5",staticStyle:{"border-radius":"8px","display":"flex"},attrs:{"input-value":active,"active-class":"purple white--text","depressed":"","rounded":"","width":"230"},on:{"click":function($event){_vm.irApp(data), toggle}}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"mb-0",attrs:{"cols":"3"}},[_c('v-img',{staticClass:"ml-3",attrs:{"width":"60","src":data.icon}})],1),_c('v-col',{attrs:{"cols":"9"}},[_c('p',{staticClass:"text-blue text-left pb-0 mb-0"},[_vm._v(_vm._s(data.name))])])],1)],1)]}}],null,true)})}),1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }