<template>
  <div class="mx-3">
    <!-- DESKTOP -->
    <div class="mb-xl-home" v-if="!isMobile">
      <v-row class="mt-10 pl-8 pr-8" justify="center">
        <v-col class="mt-1 mr-0 pr-0 pl-0" cols="9">
          <NiewMain class="v-step-1"></NiewMain>
        </v-col>
        <v-col cols="3" class="ml-0 pr-0">
          <Flash class="mt-1 v-step-2 active-tour"></Flash>
        </v-col>
        <v-col cols="12">
          <v-row align="center" justify="center">
            <v-col class="mb-0 pb-2 pl-0 ml-0" cols="12">
              <p class="text-title-xl">Otras noticias</p>
            </v-col>
            <v-col class="pl-0 pr-0" cols="12">
              <Niews class="v-step-3"></Niews>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-row align="center" justify="center">
            <v-col class="mb-0 pb-3" cols="12">
              <p class="text-title-xl">¿Qué desea hacer hoy?</p>
            </v-col>
            <v-col class="pl-0 pr-0" cols="12">
              <action-today class="v-step-4"></action-today>
            </v-col>
          </v-row>
        </v-col>
        <v-col class="ml-0 pl-0 pr-3" cols="12">
          <v-row align="center" justify="center">
            <v-col
              class="mb-10 pb-0 p-0 pr-0"
              cols="12"
              v-if="flagControlLife == 1"
            >
              <index-life></index-life>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>

    <!-- MOBILE -->
    <div class="mb-sm-home-views-1" v-else>
      <v-row class="mt-16 pt-3">
        <navbar-user class="hidden"></navbar-user>
        <v-col cols="12" class="ml-0">
          <Flash class="mt-8 v-step-2 active-tour v-step-mobile-1"></Flash>
        </v-col>
        <v-col class="mt-0 mr-0 pr-0" cols="12">
          <NiewMain class="v-step-1 v-step-mobile-2"></NiewMain>
        </v-col>
        <v-col class="mb-0 pb-0" cols="12">
          <p class="text-title-xl pb-0 mb-2 v-step-mobile-3">
            ¿Qué desea hacer hoy?
          </p>
        </v-col>
        <v-col class="pl-0 pr-0" cols="12">
          <action-today class="v-step-4"></action-today>
        </v-col>
        <v-col class="ml-0 pl-1 pr-2" cols="12">
          <index-life
            v-if="flagControlLife == 1"
            class="v-step-mobile-4"
          ></index-life>
        </v-col>
        <v-col class="ml-0 pl-4 pr-4 mb-15" cols="12">
          <!-- APLICACIONES FRECUENTES -->
          <p class="text-title-xl pb-4 mb-2 v-step-mobile-5">
            Aplicativos frecuentes
          </p>
          <v-layout justify-center>
            <v-progress-circular
              v-if="overlay"
              :size="40"
              indeterminate
              color="#466BE3"
            ></v-progress-circular>
          </v-layout>

          <v-slide-group
            multiple
            show-arrows
            prev-icon="mdi-chevron-left-circle-outline"
            next-icon="mdi-chevron-right-circle-outline"
          >
            <v-slide-item
              v-for="data in listApps"
              :key="data.id"
              v-slot="{ active, toggle }"
            >
              <div
                class="mx-2 mt-0 mb-5 app-frencuently-sm text-center"
                :input-value="active"
                active-class="purple white--text"
                depressed
                rounded
                @click="open(data)"
              >
                <div
                  style="text-align: -webkit-center; height: 60px"
                  class="d-flex justify-center align-center"
                >
                  <v-img max-width="60" :src="data.logo"> </v-img>
                </div>
                <p
                  class="txt-app-fre-sm text-center text-1-lines mb-0 index-home__apps-frequent"
                >
                  {{ data.nombre_app }}
                </p>
              </div>
            </v-slide-item>
          </v-slide-group>

          <!-- <hooper
            ref="carousel"
            :infiniteScroll="false"
            :vertical="false"
            style="height: 120px"
            :itemsToShow="itemsToShow"
            :centerMode="false"
            :playSpeed="4000"
          >
            <slide v-for="data in listApps" :key="data._ID">
              <div
                @click="irDetailsApp(data)"
                class="app-frencuently-sm text-center"
              >
                <div style="text-align: -webkit-center; height: 60px">
                  <v-img max-width="60" :src="data.logo"> </v-img>
                </div>
                <p class="txt-app-fre-sm text-center text-1-lines mb-0">
                  {{ data.nombre_app }}
                </p>
              </div>
            </slide>
          </hooper> -->
        </v-col>
      </v-row>
    </div>

    <!-- Dialog para formularios pendientes -->
    <v-dialog v-model="dialog" max-width="400">
      <v-card>
        <v-card-text class="text-left pt-5 text-pending-forms">
          <p>
            Hola
            <b class="text-capitalize"> {{ name }} </b>,
          </p>
          <p>
            Queremos recordarle que tiene formularios pendientes por
            diligenciar, puede ingresar dando click en el botón o ingresar a la
            respectiva sección para su diligenciamiento.
          </p>
          <p>Muchas gracias colaborador.</p>
        </v-card-text>
        <v-card-actions class="text-right">
          <v-btn text dark rounded class="btn-main" @click="navigateToForms()">
            Ir a formularios
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <Alert
      :open="alert.open"
      :text="alert.txt"
      :title="alert.title"
      :type="alert.type"
      :redirect="alert.redirect"
      @close="alert.open = false"
    >
    </Alert>
  </div>
</template>
<script>
import Alert from "../../components/Alert.vue";
import Niews from "./NewsLine.vue";
import NiewMain from "./NewsMain.vue";
import Flash from "./Flash.vue";
import IndexLife from "./ControlLife.vue";
import ActionToday from "./ActionToday.vue";
import moment from "moment";
import { Hooper, Slide, Pagination as HooperPagination } from "hooper";
import "hooper/dist/hooper.css";
import { ref , defineAsyncComponent } from "vue";



export default {
  data() {
    return {
      isMobile: false,
      itemsToShow: 2.5,
      listApps: [],
      overlay: false,
      step: 1,
      flagControlLife: 0,
      info: {},
      id: null,
      infoToken: {
        document: "",
      },
      token: null,
      token64: null,
      id_profile: null,
      dialog: false,
      name: "",
      alert: {
        txt: "",
        title: "",
        type: "",
        open: false,
        redirect: "",
      },
    };
  },
  components: {
    Niews,
    NiewMain,
    Flash,
    IndexLife,
    ActionToday,
    Hooper,
    Slide,
    HooperPagination,
    Alert,
  },
  computed: {
    getAppActivity() {
      let appActivity = this.$store.getters.getAppActivity;
      if (appActivity.length !== 0) {
        this.listApps = appActivity;
        this.overlay = false;
      } else {
        this.overlay = true;
      }
    },
    getUser() {
      let user = this.$store.getters.getUser;
      if (user) {
        this.info = user;
        this.id = user.profile.id;
        this.validateControlLife();
      }
    },
  },
  methods: {
    checkIfMobile() {
      this.isMobile = window.innerWidth <= 1023;
    },
    irDetailsApp(item) {
      localStorage.setItem("app", JSON.stringify(item));
      this.$router.push({
        name: "appDetails",
        params: { id: item._ID },
      });
    },
    getToken() {
      var token = localStorage.getItem("token");
      var div = token.split(".");
      if (div.length >= 2) {
        this.infoToken = JSON.parse(atob(div[1]));
      } else {
        this.infoToken = {
          document: "",
        };
      }
    },
    validateControlLife() {
      if (this.info.contractor == "brm") {
        const today = moment().format("YYYY-MM-DD");
        const allow_date = moment(this.info.date_admission)
          .add(90, "days")
          .format("YYYY-MM-DD");
        if (moment(today).isAfter(allow_date)) {
          this.flagControlLife = 1;
        }
      }
      sessionStorage.setItem(
        "flagControlLife",
        this.flagControlLife.toString()
      );
    },
    // pedingForms(id) {
    //   Api.noAuth()
    //     .profile(this.infoToken.document)
    //     .then((res) => {
    //       this.name = res.data.data.name;
    //       let tour = res.data.data.tour;
    //       let tour_mobile = res.data.data.tour_mobile;
    //       Api.noAuth()
    //         .getFormsNoResponse(this.infoToken.document, id)
    //         .then((resp) => resp.json())
    //         .then((data) => {
    //           /*
    //             Se filtra por id del formulario para evitar que la alerta aparezca con formularios especificos.
    //             Ejemplo: No queremos que se dispare el alert con el reporte diario que en este caso es el id 39
    //           */
    //           let flag = false;
    //           data.data.map((elem) => {
    //             if (elem.id !== "39") {
    //               flag = true;
    //             }
    //           });
    //           if (flag && tour && tour_mobile) {
    //             // Se comenta esto mientras mejoramos el diseño
    //             // this.dialog = true;
    //           }
    //         })
    //         .catch((error) => {
    //           console.log(error);
    //         });
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // },
    navigateToForms() {
      this.$router.push({ name: "forms" });
    },
    open(app) {
      if (app.url && app.url !== "#") {
        if (app.dispositivos_moviles == "false") {
          this.alert.open = true;
          this.alert.type = "alert";
          this.alert.title = "Upss...";
          this.alert.txt =
            "Lamentablemente, esta aplicación solo no se encuentra disponible para dispositivos móviles";
        } else {
          let newURL = document.createElement("a");
          newURL.id = "id-" + Math.floor(Math.random() * (9999999 - 500) + 500);
          newURL.href = app.url;
          newURL.target = "_blank";
          document.body.appendChild(newURL);
          newURL.click();
        }
      } else {
        this.alert.open = true;
        this.alert.type = "info";
        this.alert.title = "Para que sepas...";
        this.alert.txt = "Próximamente esta aplicación estará disponible";
      }
    },
  },
  created() {
    if (screen.width >= 708) {
      this.itemsToShow = 5.7;
    }
    this.token = localStorage.getItem("token");
    this.token64 = localStorage.getItem("tokenB64");
    this.getToken();
    this.checkIfMobile();
    window.addEventListener('resize', this.checkIfMobile);
  },
  mounted() {
    // Validar que mientras este en responsive
    if (this.$tours["myTour"]) {
      this.step = this.$tours["myTour"].currentStep;
    }
    this.$store.dispatch("updateIsHome");
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkIfMobile);
  },
};
</script>
<style>
@import "./../../assets/css/main.less";

.gradient-text {
  background: linear-gradient(to right, #006400, #008000, #00FF00, #FFFF00, #FF0000, #800000, #400000);
  -webkit-background-clip: text; /* Para navegadores basados en WebKit (Safari, Chrome) */
  color: transparent; /* Hace el texto transparente */
}

/* Opcional: Añade sombra al texto para mejorar la legibilidad */
/* .gradient-text-with-shadow {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
} */
.mdi-chevron-right-circle::before {
  content: "\F0B2A";
  color: #466be3;
}

.mdi-chevron-left-circle::before {
  content: "\F0B28";
  color: #466be3;
}

/* .active-tour {
  border: 1px red solid;
  border-radius: 0.8rem;
  box-shadow: 0 0 0 99999px rgba(0, 0, 0, 0.2);
}
.v-tour__target--highlighted {
  box-shadow: 0 0 0 99999px rgba(0, 0, 0, 0.4) !important;
} */

.text-pending-forms {
  color: #898989;
  font-family: "ProximaNovaLight";
  font-weight: 400;
  font-size: 16px;
}

.mb-sm-home-views-1 {
  display: none;
}

.v-slide-group__next,
.v-slide-group__prev {
  justify-content: center !important;
  min-width: 28px !important;
  flex: 0 1 24px !important;
}

.index-home__apps-frequent {
  max-width: 100%;
  overflow: hidden !important;
}

/* REVISAR ESTO */

/* .text-right { 
  display: flex;
  justify-content: flex-end;
} */

/* LAPTOP 4k */

@media (max-width: 1900px) {
}

/* LAPTOP LARGE */

@media (max-width: 1600px) {
}

/* LAPTOP LARGE */

@media (max-width: 1400px) {
}

/* LAPTOP NORMAL */

@media (max-width: 1200px) {
}

/* TABLET */

@media (max-width: 1023px) {
  .mb-xl-home {
    display: none;
  }
  .mb-sm-home-views-1 {
    display: block;
    text-align: initial;
  }
  .app-frencuently-sm {
    width: 108px;
    height: 87px;
    background: #ffffff;
    box-shadow: 0px 0px 1px rgba(62, 123, 250, 0.2),
      0px 2px 4px rgba(70, 107, 227, 0.4);
    border-radius: 10px;
  }

  .txt-app-fre-sm {
    font-family: "proximaNovaRegular";
    font-weight: 400;
    font-size: 12px;
    line-height: 19px;
    color: #141b33;
  }
  .v-slide-group__next,
  .v-slide-group__prev {
    justify-content: center !important;
    min-width: 35px !important;
    flex: 0 1 24px !important;
  }
}

/* MOBILE L*/
@media (max-width: 767px) {
  .mb-xl-home {
    display: none;
  }
  .mb-sm-home-views-1 {
    display: block;
    text-align: initial;
  }
  .app-frencuently-sm {
    width: 108px;
    height: 87px;
    background: #ffffff;
    box-shadow: 0px 0px 1px rgba(62, 123, 250, 0.2),
      0px 2px 4px rgba(70, 107, 227, 0.4);
    border-radius: 10px;
  }

  .txt-app-fre-sm {
    font-family: "proximaNovaRegular";
    font-weight: 400;
    font-size: 12px;
    line-height: 19px;
    color: #141b33;
  }
  .v-slide-group__next,
  .v-slide-group__prev {
    justify-content: center !important;
    min-width: 35px !important;
    flex: 0 1 24px !important;
  }
}

/* MOBILE M */
@media (max-width: 375px) {
  .mb-xl-home {
    display: none;
  }
  .mb-sm-home-views-1 {
    display: block;
    text-align: initial;
  }
  .app-frencuently-sm {
    width: 108px;
    height: 87px;
    background: #ffffff;
    box-shadow: 0px 0px 1px rgba(62, 123, 250, 0.2),
      0px 2px 4px rgba(70, 107, 227, 0.4);
    border-radius: 10px;
  }

  .txt-app-fre-sm {
    font-family: "proximaNovaRegular";
    font-weight: 400;
    font-size: 12px;
    line-height: 19px;
    color: #141b33;
  }
}

/* MOBILE S */
@media (max-width: 320px) {
  .mb-xl-home {
    display: none;
  }
  .mb-sm-home-views-1 {
    display: block;
    text-align: initial;
  }
  .app-frencuently-sm {
    width: 108px;
    height: 87px;
    background: #ffffff;
    box-shadow: 0px 0px 1px rgba(62, 123, 250, 0.2),
      0px 2px 4px rgba(70, 107, 227, 0.4);
    border-radius: 10px;
  }

  .txt-app-fre-sm {
    font-family: "proximaNovaRegular";
    font-weight: 400;
    font-size: 12px;
    line-height: 19px;
    color: #141b33;
  }

  .v-slide-group__next,
  .v-slide-group__prev {
    justify-content: center !important;
    min-width: 35px !important;
    flex: 0 1 24px !important;
  }
}
</style>
